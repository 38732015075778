import { convertToRaw, EditorState, convertFromRaw } from "draft-js";
import { useState } from "react";
import { func, object } from "prop-types";
import css from './rte.module.css';

export default function RTE({ content, onChange, label }) {
    if (typeof window === 'undefined') return null;
    const { Editor } = require("react-draft-wysiwyg");
    require("react-draft-wysiwyg/dist/react-draft-wysiwyg.css");
    let data = {
        entityMap: {},
        blocks: []
    };

    if (content
        && Object.keys(content).includes('entityMap')
        && Object.keys(content).includes('blocks')) {
        data = content
    }
    const [editorState, setEditorState] = useState(EditorState.createWithContent(convertFromRaw(data)));

    const onEditorStateChange = function (editorState) {
        setEditorState(editorState);
        onChange(convertToRaw(editorState.getCurrentContent()));
    };

    return (
        <>
            <p>{label}</p>
            <div className={css.editor}>
                <Editor
                    editorState={editorState}
                    toolbarClassName="toolbarClassName"
                    wrapperClassName="wrapperClassName"
                    editorClassName="editorClassName"
                    onEditorStateChange={onEditorStateChange}
                />
            </div>
        </>
    );
}

RTE.defaultProps = {
    content: {
        entityMap: {},
        blocks: []
    },
    onChange: () => { }
};

RTE.propTypes = {
    content: object,
    onChange: func.isRequired,
};
