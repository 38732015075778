import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './About.module.css';
import image from './about-us-1056.png';


const About = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
    <div>Gritty In Pink is creating&nbsp;a new marketplace network, connecting women who work in all aspects of the music business,&nbsp;with industry&nbsp;pros of any gender&nbsp;who want to hire diverse women. INPINK.com is building&nbsp;a pipeline of talented non-binary/female freelancers, helping&nbsp;solve the male-dominated industry&apos;s&nbsp;urgent Diversity, Equity and Inclusion&nbsp;problems.</div>
    <div><br/></div>
    <div>The Gritty In Pink community originally formed in January 2020, with a string of successful monthly shows in LA, then due to COVID shifted to IG livestream fundraisers, which have raised thousands of dollars for charities and garnered over 1 Million Instagram impressions. We have featured over 200 artists with a collective following of 4M+ on IG and 7M+ on tiktok, partnered with brands from Fender to ACLU, and continue to strive towards our mission of achieving gender equity in the music business- and beyond.&nbsp;</div>
    <div><br/></div>
    <div>For more info and updates, please visit our website and follow us on social media- links below!</div>
    </div>
  );
};

About.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

About.propTypes = {
  rootClassName: string,
  className: string,
};

export default About;
