import React, { Component } from 'react';
import Swal from 'sweetalert2';
import { compose } from 'redux';
import classNames from 'classnames';
import { string, bool } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';


import config from '../../../config';
import * as validators from '../../../util/validators';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../../util/types';
import { FormattedMessage, intlShape, injectIntl } from '../../../util/reactIntl';
import { Form, PrimaryButton, FieldCurrencyInput, FieldSelect, FieldTextInput } from '../..';
import { TRANSITION_REQUEST_AFTER_CONTACT } from '../../../util/transaction';

import css from './AdjustPriceForm.module.css';

const showPricePanelAfterSubmit = true;

export class AdjustPriceFormComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      focusedInput: null,
      showAdvancedOptions: false, // New state for advanced options visibility
    };
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.onFocusedInputChange = this.onFocusedInputChange.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.toggleAdvancedOptions = this.toggleAdvancedOptions.bind(this); // New method to toggle advanced options
  }

  // Function that can be passed to nested components
  // so that they can notify this component when the
  // focused input changes.
  onFocusedInputChange(focusedInput) {
    this.setState({ focusedInput });
  }

  toggleAdvancedOptions() {
    this.setState(prevState => ({
      showAdvancedOptions: !prevState.showAdvancedOptions,
    }));
  }

  handleFormSubmit(e) {
    console.log(e)
    let title;
    if (e.depositType != 'na') {
      title = 'Are you sure you want to update the price? <pre>*Please note, deposits are your responsibility to refund.</pre>'
    } else {
      title = 'Are you sure you want to update the price?'

    }
    Swal.fire({
      title: title,
      showCancelButton: true,
      confirmButtonText: 'Yes',
      customClass: {
        actions: 'my-actions',
        cancelButton: 'order-1 right-gap',
        confirmButton: 'order-2',
      },
    }).then(result => {
      if (result.isConfirmed) {
        Swal.fire('Updated!', '', 'success');
        this.props.onSubmit(e);
      }
    });
    const updatedPrice = e.updatedPrice;
    if (!updatedPrice) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('updatedPrice');
      formApi.focus('updatedPrice');
    }
  }

  // When the values of the form are updated we need to fetch
  // lineItems from FTW backend for the EstimatedTransactionMaybe
  // In case you add more fields to the form, make sure you add
  // the values here to the bookingData object.
  handleOnChange(formValues) {
    const updatedPrice = formValues.values && formValues.values.updatedPrice ? formValues.values.updatedPrice : {};
    if (updatedPrice) {
      return updatedPrice;
    }
  }
  

  render() {
    const { rootClassName, className, price: unitPrice, currentTransaction, isStripePayoutDetails, onCloseStripePayoutModal, ...rest } = this.props;
    const { isInquiry } = currentTransaction.attributes.metadata || {};
    const requestAfterContact = currentTransaction.attributes.lastTransition === TRANSITION_REQUEST_AFTER_CONTACT;

    const classes = classNames(rootClassName || css.root, className);

    if (unitPrice.currency !== config.currency) {
      return (
        <div className={classes}>
          <p className={css.error}>
            <FormattedMessage id="AdjustPriceForm.listingCurrencyInvalid" />
          </p>
        </div>
      );
    }

    return (
      <FinalForm
        {...rest}
        unitPrice={unitPrice}
        onSubmit={this.handleFormSubmit}
        render={fieldRenderProps => {
          const {
            handleSubmit,
            intl,
            submitButtonWrapperClassName,
            unitType,
            values,
            // form,
          } = fieldRenderProps;

          const isNightly = unitType === LINE_ITEM_NIGHT;
          const isDaily = unitType === LINE_ITEM_DAY;
          const pricePlaceholderMessage = intl.formatMessage({
            id: 'AdjustPriceForm.priceInputPlaceholder',
          });
          const priceRequired = validators.required(
            intl.formatMessage({
              id: 'EditListingPricingForm.priceRequired',
            })
          );

          const priceValidators = config.listingMinimumPriceSubUnits
            ? validators.composeValidators(priceRequired, minPriceRequired)
            : priceRequired;

          const translationKey = isNightly
            ? 'EditListingPricingForm.pricePerNight'
            : isDaily
            ? 'EditListingPricingForm.pricePerDay'
            : 'EditListingPricingForm.pricePerUnit';

          const pricePerUnitMessage = intl.formatMessage({
            id: translationKey,
          });

          const submitButtonClasses = classNames(submitButtonWrapperClassName || css.submitButtonWrapper);

          const depositTypes = [
            { key: 'na', label: 'Not Applicable' },
            { key: 'flat', label: 'Flat' },
            { key: 'percent', label: 'Percentage' },
          ];

          return (
            <div className={css.pricePanel}>
              {showPricePanelAfterSubmit ? (
                <Form onSubmit={handleSubmit} className={classes} enforcePagePreloadFor="CheckoutPage">
                  <FormSpy
                    subscription={{ values: true }}
                    onChange={values => {
                      this.handleOnChange(values);
                    }}
                  />
                  <div className={css.mobileTop}>
                    <FieldCurrencyInput
                      id="updatedPrice"
                      name="updatedPrice"
                      className={css.priceInput}
                      autoFocus
                      label={pricePerUnitMessage}
                      placeholder={pricePlaceholderMessage}
                      currencyConfig={config.currencyConfig}
                      validate={priceValidators}
                    />
                    <div className={css.advancedOptions}>
                      <button
                        type="button"
                        className={css.advancedOptionsButton}
                        onClick={this.toggleAdvancedOptions}
                      >
                        Advanced Options{' '}
                        <FontAwesomeIcon
                          icon={this.state.showAdvancedOptions ? faAngleUp : faAngleDown}
                          className={css.arrowIcon}
                        />                      </button>
                      {this.state.showAdvancedOptions && (
                        <div className={css.advancedOptionsContent}>
                          <FieldSelect id="depositType" name="depositType" label="Deposit Type">
                            {depositTypes.map(t => (
                              <option key={t.key} value={t.key}>
                                {t.label}
                              </option>
                            ))}
                          </FieldSelect>

                          {values.depositType === 'flat' ? (
                            <FieldCurrencyInput
                              name="deposit"
                              id="deposit"
                              label="Deposit Value"
                              className={css.priceInput}
                              autoFocus
                              placeholder={pricePlaceholderMessage}
                              currencyConfig={config.currencyConfig}
                              validate={validators.composeValidators(
                                ((message) => (value) =>
                                  Number(value?.amount) <= values.updatedPrice?.amount / 2
                                    ? undefined
                                    : message)('Cannot be greater than 50% of the original price.'),
                                priceRequired
                              )}
                            />
                          ) : values.depositType === 'percent' ? (
                            <FieldTextInput
                              type="number"
                              name="deposit"
                              id="deposit"
                              className={css.priceInput}
                              label="Deposit Percentage"
                              validate={((message) => (value) => (value <= 50 ? undefined : message))(
                                'Cannot be greater than 50%.'
                              )}
                            />
                          ) : null}
                        </div>
                      )}
                    </div>

                    <div className={`${css.buttonContainer} ${submitButtonClasses}`}>
                      <PrimaryButton
                        type="button"
                        onClick={() => (!isStripePayoutDetails ? onCloseStripePayoutModal(true) : handleSubmit(values))}
                      >

                        
                        <FormattedMessage
                          id={
                            requestAfterContact
                              ? 'AdjustPriceForm.setPrice'
                              : isInquiry
                              ? 'AdjustPriceForm.quotePrice'
                              : 'AdjustPriceForm.setPrice'
                          }
                        />
                      </PrimaryButton>
                    </div>

                  </div>
                  <p>
                    <FormattedMessage id="AdjustPriceForm.info" />
                  </p>
                </Form>
              ) : null}
            </div>
          );
        }}
      />
    );
  }
}

AdjustPriceFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  submitButtonWrapperClassName: null,
  price: null,
  isOwnListing: true,
  adjustedPricePlaceholder: null,
  endDatePlaceholder: null,
};

AdjustPriceFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  submitButtonWrapperClassName: string,

  // unitType: propTypes.bookingUnitType.isRequired,
  price: propTypes.money,
  isOwnListing: bool,

  // from injectIntl
  intl: intlShape.isRequired,
};

const AdjustPriceForm = compose(injectIntl)(AdjustPriceFormComponent);
AdjustPriceForm.displayName = 'AdjustPriceForm';

export default AdjustPriceForm;
