import React from 'react';
import { compose } from 'redux';
import { withRouter } from 'react-router-dom';
import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { arrayOf, array, bool, func, node, oneOfType, shape, string } from 'prop-types';
import classNames from 'classnames';
import omit from 'lodash/omit';
import { propTypes, LISTING_STATE_CLOSED, LINE_ITEM_NIGHT, LINE_ITEM_DAY } from '../../util/types';
import { formatMoney } from '../../util/currency';
import { parse, stringify } from '../../util/urlHelpers';
import config from '../../config';
import { ModalInMobile, Button } from '..';
import AdjustPriceForm from './AdjustPriceForm/AdjustPriceForm';

import css from './AdjustPricePanel.module.css';

// This defines when ModalInMobile shows content as Modal
const MODAL_BREAKPOINT = 1023;

const priceData = (updatedPrice, intl) => {
  if (updatedPrice && updatedPrice.currency === config.currency) {
    const formattedPrice = formatMoney(intl, updatedPrice);
    return { formattedPrice, priceTitle: formattedPrice };
  } else if (updatedPrice) {
    return {
      formattedPrice: `(${updatedPrice.currency})`,
      priceTitle: `Unsupported currency (${updatedPrice.currency})`,
    };
  }
  return {};
};

const openBookModal = (isOwnListing, isClosed, history, location) => {
  if (isOwnListing || isClosed) {
    window.scrollTo(0, 0);
  } else {
    const { pathname, search, state } = location;
    const searchString = `?${stringify({ ...parse(search), book: true })}`;
    history.push(`${pathname}${searchString}`, state);
  }
};

const closeBookModal = (history, location) => {
  const { pathname, search, state } = location;
  const searchParams = omit(parse(search), 'book');
  const searchString = `?${stringify(searchParams)}`;
  history.push(`${pathname}${searchString}`, state);
};
 
const AdjustPricePanel = props => {
  const {
    rootClassName,
    className,
    titleClassName,
    listing,
    isOwnListing,
    unitType,
    onUpdatePrice,
    title,
    subTitle,
    currentTransaction,
    authorDisplayName,
    onManageDisableScrolling, 
    history,
    location,
    intl,
    onSubmit,
    isStripePayoutDetails,
    onCloseStripePayoutModal,
  } = props;


  const price = listing.attributes.price;
  const hasListingState = !!listing.attributes.state;
  const isClosed = hasListingState && listing.attributes.state === LISTING_STATE_CLOSED;
  const showAdjustPriceForm = hasListingState && !isClosed;
  const showClosedListingHelpText = listing.id && isClosed;
  const updatedPrice = currentTransaction.attributes.protectedData ? currentTransaction.attributes.protectedData.updatedPrice : null;
  const { formattedPrice, priceTitle } = priceData(updatedPrice ? updatedPrice : 0, intl);
  const isBook = !!parse(location.search).book;

  const subTitleText = !!subTitle
    ? subTitle
    : showClosedListingHelpText
    ? intl.formatMessage({ id: 'AdjustPricePanel.subTitleClosedListing' })
    : null; 

  // const updatedPrice = protectedData && protectedData.updatedPrice;
  // initialValues = updatedPrice;

  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;

  const classes = classNames(rootClassName || css.root, className);
  const titleClasses = classNames(titleClassName || css.adjustPriceTitle);

  return (
    <div className={classes}>
      <div className={css.adjustPriceContainer}>
      <div className={css.modalHeading}>
          <h1 className={css.title}>{title}</h1>
        </div>

        {showAdjustPriceForm ? (
          <AdjustPriceForm
            className={css.adjustPriceForm}
            formId="AdjustPricePanel"
            submitButtonWrapperClassName={css.adjustPriceSubmitButtonWrapper}
            unitType={unitType}
            onSubmit={onSubmit}
            initialValues={{depositType: 'na', deposit: 0}}
            price={price}
            listingId={listing.id}
            isOwnListing={isOwnListing}
            currentTransaction={currentTransaction}
            isStripePayoutDetails={isStripePayoutDetails}
            onCloseStripePayoutModal={onCloseStripePayoutModal}
          />
        ) : null}
      <div className={css.openAdjustPriceForm}>
        <div className={css.priceContainer}>
          <div className={css.priceValue} title={priceTitle}>
            {formattedPrice}
          </div>
        </div>

      </div>
      </div>

    </div>
  );
};

AdjustPricePanel.defaultProps = {
  rootClassName: null,
  className: null,
  titleClassName: null,
  isOwnListing: false,
  subTitle: null,
  unitType: config.adjustPriceUnitType,

};

AdjustPricePanel.propTypes = {
  rootClassName: string,
  className: string,
  titleClassName: string,
  listing: oneOfType([propTypes.listing, propTypes.ownListing]),
  isOwnListing: bool,
  unitType: propTypes.adjustPriceUnitType,
  onSubmit: func.isRequired,
  title: oneOfType([node, string]).isRequired,
  subTitle: oneOfType([node, string]),
  authorDisplayName: oneOfType([node, string]).isRequired,
  onManageDisableScrolling: func.isRequired,


  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
  location: shape({
    search: string,
  }).isRequired,

  // from injectIntl
  intl: intlShape.isRequired,
};

export default compose(
  withRouter,
  injectIntl
)(AdjustPricePanel);
