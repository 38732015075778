import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { bool } from 'prop-types';

import css from './OrderBreakdown.module.css';

const LineItemDiscountMaybe = props => {
  const { lineItems, isCustomer, intl } = props;

  let discountAmount = null;
  let discountName= null;
  let lineItem = null;

  // TODO: Remove hardcoding
  // please remove hardcoding
  if (lineItems.length === 4 && isCustomer) {
    discountAmount = lineItems[1].lineTotal;
    const formattedDiscountAmount = formatMoney(intl, discountAmount);

    discountName = lineItems[1].percentage === null ? 
    '$20 OFF' : 'Service Fee Waiver'; 
    return lineItem = (
        <>
          <div className={css.discountTotal}>
            <div className={css.discountLabel}>{discountName}</div>
            <div className={css.discountPrice}>{formattedDiscountAmount}</div>
          </div>
        </>
      );
  }



  return (
    <div>
    {lineItem}

    </div>
  );
};

LineItemDiscountMaybe.propTypes = {
    lineItems: propTypes.lineItems.isRequired,
  intl: intlShape.isRequired,
  isCustomer: bool.isRequired,

};

export default LineItemDiscountMaybe;
