import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './ContactUs.module.css';

const ContactUs = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  // prettier-ignore
  return (
    <div className={classes}>
    <iframe className={css.desktop} src="https://docs.google.com/forms/d/e/1FAIpQLSfQqAAvrNdaMzv7TnayJ4i0qpbIW9Ksh1dgFNwqlL4yZVGBdA/viewform?embedded=true" width="800" height="1000" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>    
    <iframe className={css.mobile} src="https://docs.google.com/forms/d/e/1FAIpQLSfQqAAvrNdaMzv7TnayJ4i0qpbIW9Ksh1dgFNwqlL4yZVGBdA/viewform?embedded=true" width="350" height="809" frameborder="0" marginheight="0" marginwidth="0">Loading…</iframe>
    </div>
  );
};

ContactUs.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

ContactUs.propTypes = {
  rootClassName: string,
  className: string,
};

export default ContactUs;
