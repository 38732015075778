import React from 'react'
import { Form as FinalForm } from 'react-final-form';
import { FieldCurrencyInput, FieldSelect, FieldTextInput, Form, PrimaryButton } from '..';
import { FormattedMessage } from 'react-intl';
import { composeValidators, required } from '../../util/validators';
import css from './NewQuoteFrom.module.css';
import { LINE_ITEM_DAY, LINE_ITEM_NIGHT } from '../../util/types';
import config from '../../config';

function NewQuoteForm(props) {
    return <FinalForm
        {...props}

        render={fieldRenderProps => {
            const {
                formId,
                values,
                handleSubmit,
                inProgress,
                invalid,
                intl,
                unitType
            } = fieldRenderProps;

            const isNightly = unitType === LINE_ITEM_NIGHT;
            const isDaily = unitType === LINE_ITEM_DAY;
            const pricePlaceholderMessage = intl.formatMessage({
                id: 'AdjustPriceForm.priceInputPlaceholder',
            });
            const priceRequired = required(
                intl.formatMessage({
                    id: 'EditListingPricingForm.priceRequired',
                })
            );

            const translationKey = isNightly
                ? 'EditListingPricingForm.pricePerNight'
                : isDaily
                    ? 'EditListingPricingForm.pricePerDay'
                    : 'EditListingPricingForm.pricePerUnit';

            const pricePerUnitMessage = intl.formatMessage({
                id: translationKey,
            });

            const depositTypes = [
                { key: 'na', label: 'Not Applicable' },
                { key: 'flat', label: 'Flat' },
                { key: 'percent', label: 'Percentage' }
            ]

            return (
                <Form onSubmit={handleSubmit} className={css.form} enforcePagePreloadFor="CheckoutPage">
                    <p className={css.awaiting}>Send a Quote to Rebook</p>
                    <div className={css.mobileTop}>
                        <FieldCurrencyInput
                            id="updatedPrice"
                            name="updatedPrice"
                            className={css.priceInput}
                            label={pricePerUnitMessage}
                            placeholder={pricePlaceholderMessage}
                            currencyConfig={config.currencyConfig}
                            validate={priceRequired}
                        />

                        <FieldSelect
                            id='depositType'
                            name='depositType'
                            label='Deposit Type'
                        >
                            {
                                depositTypes.map(t => (
                                    <option key={t.key} value={t.key} > {t.label} </option>
                                ))
                            }
                        </FieldSelect>

                        {
                            values.depositType === 'flat' ?
                                <FieldCurrencyInput
                                    name='deposit'
                                    id='deposit'
                                    label='Deposit Value'
                                    className={css.priceInput}
                                    placeholder={pricePlaceholderMessage}
                                    currencyConfig={config.currencyConfig}
                                    validate={composeValidators(((message) => (value) => Number(value?.amount) <= values.updatedPrice?.amount ? undefined : message)('Cant be greater then Orignal Price'), priceRequired)}
                                /> :
                                values.depositType === 'percent' ?
                                    <FieldTextInput
                                        type='number'
                                        name='deposit'
                                        id='deposit'
                                        className={css.priceInput}
                                        label='Deposit Percentage'
                                        validate={((message) => (value) => value <= 100 ? undefined : message)('Cant be greater then 100')}
                                    /> : null
                        }

                        <div>
                            <PrimaryButton
                                className={css.submit}
                                inProgress={inProgress}
                                disabled={invalid}
                            >
                                <FormattedMessage id="AdjustPriceForm.quotePrice" />
                            </PrimaryButton>
                        </div>
                    </div>
                    <p><FormattedMessage id="AdjustPriceForm.info" /></p>
                </Form>
            );
        }}
    />
}

export default NewQuoteForm

