import React from 'react';
import { string } from 'prop-types';
import { injectIntl, intlShape } from '../../util/reactIntl';
import classNames from 'classnames';
import {
  Ig,
  Fb,
  Tr,
  Yt,
  Tt,
  Gritty,
  LiveNation,
  Tunecore
} from '../../assets/images'

import css from './Footer.module.css';

const Footer = props => {
  const { rootClassName, className, intl } = props;
  const classes = classNames(rootClassName || css.root, className);

  return (
    <div className={classes}>
              <div id={css.socials}>
          <div className={css.socialIcons}>
            <span className={css.socialIcon}>
              <a href="https://www.instagram.com/grittyinpinklife/?hl=en" target="_blank">
                <img className={css.socialImg} src={Ig} loading="lazy"/>
              </a>
            </span>
            <span className={css.socialIcon}>
              <a href="https://www.facebook.com/grittyinpinklife/" target="_blank">
                <img className={css.socialImg} src={Fb} loading="lazy"/>
              </a>
            </span>
            <span className={css.socialIcon}>
              <a href="https://twitter.com/grittynpinklife" target="_blank">
                <img className={css.socialImg} src={Tr} loading="lazy"/>
              </a>
            </span>
            <span className={css.socialIcon}>
              <a href="https://www.youtube.com/channel/UCx_8rK5GQzfbB9OUqPruMDQ" target="_blank">
                <img className={css.socialImg} src={Yt} loading="lazy"/>
              </a>
            </span>
            <span className={css.socialIcon}>
              <a href="https://www.tiktok.com/@grittyinpink?lang=en" target="_blank">
                <img className={css.socialImg} src={Tt} loading="lazy"/>
              </a>
            </span>
          </div>
        </div>
        <div className={css.gritty}>
          <span>Powered By:</span>
          <a href="https://www.grittyinpink.co/" target="_blank">
            <img className={css.grittyTitle} src={Gritty} loading="lazy" />
          </a>
        </div>
        <div className={css.subLinkGroupSocials}>
          <a href="http://livenation.com/" target="_blank">
              <img className={css.liveNation} src={LiveNation} loading="lazy" />
          </a> 
          {/* <a href="https://www.tunecore.com/" target="_blank">
              <img className={css.tunecore} src={Tunecore} loading="lazy" />
          </a> */}
        </div>  
        <div className={css.subLinkGroup}>
          <a href='/privacy-policy'>Private Policy</a>
          <a href='/terms-of-service'>Terms of Service</a>
        </div>
    </div>
  );
};

Footer.defaultProps = {
  rootClassName: null,
  className: null,
};

Footer.propTypes = {
  rootClassName: string,
  className: string,
  intl: intlShape.isRequired,
};

export default injectIntl(Footer);
