import classNames from 'classnames';
import React, { useState } from 'react';
import css from './StaticTab.module.css'
import { SecondaryButton } from '..';

const Tab = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabClick = (tabIndex) => {
    setActiveTab(tabIndex);
  };

  return (
    <div className={css.tabs}>
      <div className={css.tabHeader}>
        {tabs.map((tab, index) => (
          <SecondaryButton
            key={index}
            className={classNames(css.tab, {[css.active]: activeTab === index})}
            onClick={() => handleTabClick(index)}
          >
            {tab.title}
          </SecondaryButton>
        ))}
      </div>
      <div className={css.tabContent}>
        {tabs[activeTab].content}
      </div>
    </div>
  );
};

export default Tab;
