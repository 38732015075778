import unionWith from 'lodash/unionWith';

import config from '../../config';
import { storableError } from '../../util/errors';
import { convertUnitToSubUnit, unitDivisor } from '../../util/currency';
import { parseDateFromISO8601, getExclusiveEndDate } from '../../util/dates';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { isOriginInUse, isStockInUse } from '../../util/search';
import { parse } from '../../util/urlHelpers';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';

// Pagination page size might need to be dynamic on responsive page layouts
// Current design has max 3 columns 12 is divisible by 2 and 3
// So, there's enough cards to fill all columns on full pagination pages
const RESULT_PAGE_SIZE = 24;

// ================ Action types ================ //

export const SEARCH_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_LISTINGS_REQUEST';
export const SEARCH_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_LISTINGS_SUCCESS';
export const SEARCH_LISTINGS_ERROR = 'app/SearchPage/SEARCH_LISTINGS_ERROR';

export const SEARCH_MAP_LISTINGS_REQUEST = 'app/SearchPage/SEARCH_MAP_LISTINGS_REQUEST';
export const SEARCH_MAP_LISTINGS_SUCCESS = 'app/SearchPage/SEARCH_MAP_LISTINGS_SUCCESS';
export const SEARCH_MAP_LISTINGS_ERROR = 'app/SearchPage/SEARCH_MAP_LISTINGS_ERROR';

export const SEARCH_MAP_SET_ACTIVE_LISTING = 'app/SearchPage/SEARCH_MAP_SET_ACTIVE_LISTING';

// ================ Reducer ================ //

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  searchMapListingsError: null,
};

const resultIds = data => data.data.map(l => l.id);

const listingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case SEARCH_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case SEARCH_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case SEARCH_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };

    case SEARCH_MAP_LISTINGS_REQUEST:
      return {
        ...state,
        searchMapListingsError: null,
      };
    case SEARCH_MAP_LISTINGS_SUCCESS: {
      const searchMapListingIds = unionWith(
        state.searchMapListingIds,
        resultIds(payload.data),
        (id1, id2) => id1.uuid === id2.uuid
      );
      return {
        ...state,
        searchMapListingIds,
      };
    }

    case SEARCH_MAP_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchMapListingsError: payload };

    case SEARCH_MAP_SET_ACTIVE_LISTING:
      return {
        ...state,
        activeListingId: payload,
      };

    default:
      return state;
  }
};

export default listingPageReducer;

// ================ Action creators ================ //

export const searchListingsRequest = searchParams => ({
  type: SEARCH_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: SEARCH_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchListingsError = e => ({
  type: SEARCH_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchMapListingsRequest = () => ({ type: SEARCH_MAP_LISTINGS_REQUEST });

export const searchMapListingsSuccess = response => ({
  type: SEARCH_MAP_LISTINGS_SUCCESS,
  payload: { data: response.data },
});

export const searchMapListingsError = e => ({
  type: SEARCH_MAP_LISTINGS_ERROR,
  error: true,
  payload: e,
});


export const searchListings = searchParams => (dispatch, getState, sdk) => {
  if ((searchParams.sort !== 'meta_isPromoted' && searchParams.sort) || !searchParams.keywords) {
    // Regular search
    dispatch(searchListingsRequest(searchParams));

    const priceSearchParams = priceParam => {
      const inSubunits = value =>
        convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
      const values = priceParam ? priceParam.split(',') : [];
      return priceParam && values.length === 2
        ? {
          price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
        }
        : {};
    };

    const datesSearchParams = datesParam => {
      const values = datesParam ? datesParam.split(',') : [];
      const hasValues = datesParam && values.length === 2;
      const startDate = hasValues ? values[0] : null;
      const isNightlyBooking = config.lineItemUnitType === 'line-item/night';
      const endDate = hasValues && isNightlyBooking
        ? values[1]
        : hasValues
          ? getExclusiveEndDate(values[1], 'Etc/UTC')
          : null;

      return hasValues
        ? {
          start: parseDateFromISO8601(startDate, 'Etc/UTC'),
          end: parseDateFromISO8601(endDate, 'Etc/UTC'),
          availability: 'full', // Availability can be full or partial. Default value is full.

        }
        : {};
    };

    const { perPage, price, dates, sort, ...rest } = searchParams;
    const priceMaybe = priceSearchParams(price);
    const datesMaybe = datesSearchParams(dates);
    const sortMaybe = sort === config.custom.sortConfig.relevanceKey ? {} : { sort };

    const params = {
      ...rest,
      ...priceMaybe,
      ...datesMaybe,
      ...sortMaybe,
      per_page: perPage,
    };

function increaseBoundsBy300Miles(bounds) {
  // fixing ssr error
  if (!bounds) return null;
  const milesPerDegree = 69; // Approximation: 1 degree of latitude/longitude is approximately 69 miles

  // Calculate the adjustment for latitude and longitude
  const latAdjustment = 300 / milesPerDegree;
  const lngAdjustment = 300 / (milesPerDegree * Math.cos(bounds.sw.lat * Math.PI / 180));

  // Create the increased bounds object
  const increasedBounds = {
    ne: {
      lat: bounds.ne.lat + latAdjustment,
      lng: bounds.ne.lng + lngAdjustment,
    },
    sw: {
      lat: bounds.sw.lat - latAdjustment,
      lng: bounds.sw.lng - lngAdjustment,
    },
  };

  return increasedBounds;
}

const listings = sdk.listings
  .query(params)
  .then(response => {
    dispatch(addMarketplaceEntities(response));
    dispatch(searchListingsSuccess(response));

    if (response.data.data.length < 6) {
      const newBounds = increaseBoundsBy300Miles(params.bounds);
      let newParams = params;
      newParams = {bounds: newBounds, ... newParams}

      return new Promise((resolve, reject) => {
        sdk.listings.query(newParams)
          .then(response => {
            dispatch(addMarketplaceEntities(response));
            dispatch(searchListingsSuccess(response));

            if (response.data.data.length > 6) {
              const data = response.data.data;
              const deleteCount = data.length - 6;
              const newData = { data: data.splice(0, deleteCount), ...response };
              resolve(newData);
            } else {
              resolve(response);
            }
          })
          .catch(e => {
            dispatch(searchListingsError(storableError(e)));
            reject(e);
          });
      });
    }

    return response;
  })
  .catch(e => {
    dispatch(searchListingsError(storableError(e)));
    throw e;
  });


return listings;

  } else {
    // Promoted listings search
    dispatch(searchListingsRequest(searchParams));

    const priceSearchParams = priceParam => {
      const inSubunits = value =>
        convertUnitToSubUnit(value, unitDivisor(config.currencyConfig.currency));
      const values = priceParam ? priceParam.split(',') : [];
      return priceParam && values.length === 2
        ? {
            price: [inSubunits(values[0]), inSubunits(values[1]) + 1].join(','),
          }
        : {};
    };

    const datesSearchParams = datesParam => {
      const values = datesParam ? datesParam.split(',') : [];
      const hasValues = datesParam && values.length === 2;
      const startDate = hasValues ? values[0] : null;
      const isNightlyBooking = config.lineItemUnitType === 'line-item/night';
      const endDate = hasValues && isNightlyBooking
        ? values[1]
        : hasValues
          ? getExclusiveEndDate(values[1], 'Etc/UTC')
          : null;


      return hasValues
        ? {
          start: parseDateFromISO8601(startDate, 'Etc/UTC'),
          end: parseDateFromISO8601(endDate, 'Etc/UTC'),
          availability: 'full', // Availability can be full or partial. Default value is full.
        }
        : {};
    };

    const { perPage, price, dates, sort, ...rest } = searchParams;
    const priceMaybe = priceSearchParams(price);
    const datesMaybe = datesSearchParams(dates);
    const sortMaybe = sort === config.custom.sortConfig.relevanceKey
      ? {}
      : sort
        ? { sort }
        : { sort: 'meta_isPromoted' };

    const params = {
      ...rest,
      ...priceMaybe,
      ...datesMaybe,
      ...sortMaybe,
      per_page: perPage,
    };

    // Params for if the featured sort is selected.
    const promotedParams = sortMaybe.sort === 'meta_isPromoted'
      ? {
        ...rest,
        ...priceMaybe,
        ...datesMaybe,
        ...sortMaybe,
        meta_isPromoted: true,
        per_page: 12,
      }
      : params;

    // Queries promoted listings
    const promotedListingResults = sdk.listings
      .query(promotedParams)
      .then(response => response)
      .catch(e => {
        dispatch(searchListingsError(storableError(e)));
        throw e;
      });

    // Queries regular listings
    const listingResults = sdk.listings
      .query(params)
      .then(response => response)
      .catch(e => {
        dispatch(searchListingsError(storableError(e)));
        throw e;
      });

      // Combines promises
      const combinedResults = Promise.all([promotedListingResults, listingResults]).then(valArray => {
        // If promoted listings exist, combines data array
        const combinedData = valArray[0].data.data.length > 0
          ? valArray[0].data.data.concat(valArray[1].data.data)
          : valArray[1].data.data;

        // If promoted listings exist, combines included array
        const combinedAssets = valArray[0].data.data.length > 0
          ? valArray[0].data.included.concat(valArray[1].data.included)
          : valArray[1].data.included;

        // Goes through the uuid's of the combined data, and extracts the uuids into an array
        const listingResultIds = combinedData.map(o => o.id.uuid);

        // Function to check duplicate listings and return indexes of duplicate occurrences
        function checkDuplicates(str, arr) {
          const matches = [];
          for (let i = 0; i < arr.length; i++) {
            if (arr[i] === str) {
              matches.push(i);
            }
          }
          return matches;

        }

        // This piece iterates through combined results, runs the function to check for duplicates,
        // and splices the last listing from results if they have two occurrences.
        let uniqueData = combinedData;

        for (let i = 0; i < combinedData.length; i++) {
          if (combinedData[i].id.uuid === '636cc813-bf9f-4d59-a792-a4741ec2eee7') {
            const item = uniqueData.splice(i, 1); // Remove item at indexToMoveToFront
            uniqueData.unshift(item[0]); // Add the removed item to the front of the array
          }
        }

        for (let i = 0; i < listingResultIds.length; i++) {
          const duplicates = checkDuplicates(listingResultIds[i], listingResultIds);
          if (duplicates.length === 2) {
            uniqueData.splice(duplicates[1], 1);
          }
        }

        // Honestly not even sure if this is useful, does the same thing as above but to the assets
        const assetIds = combinedAssets !== undefined ? combinedAssets.map(o => o.id) : [];
        let uniqueAssets = combinedAssets;

        for (let i = 0; i < assetIds.length; i++) {
          const duplicates = checkDuplicates(assetIds[i], assetIds);
          if (duplicates.length === 2) {
            uniqueAssets.splice(duplicates[1], 1);
          }
        }

        // This is the updated data object
        const updatedDataObject = {
          data: uniqueData,
          included: combinedAssets,
          meta: valArray[1].data.meta,
        };
        const combinedListingResults = {
          data: updatedDataObject,
          status: valArray[1].status,
          statusText: valArray[1].statusText,
        };

        dispatch(addMarketplaceEntities(combinedListingResults));
        dispatch(searchListingsSuccess(combinedListingResults));
        return updatedDataObject;
      });

      return combinedResults;
    }
  };


export const setActiveListing = listingId => ({
  type: SEARCH_MAP_SET_ACTIVE_LISTING,
  payload: listingId,
});

export const searchMapListings = searchParams => (dispatch, getState, sdk) => {
  dispatch(searchMapListingsRequest(searchParams));

  const { perPage, ...rest } = searchParams;
  const params = {
    ...rest,
    per_page: perPage,
  };

  return sdk.listings
    .query(params)
    .then(response => {
      dispatch(addMarketplaceEntities(response));
      dispatch(searchMapListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchMapListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params = {}, search) => {
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  // Add minStock filter with default value (1), if stock management is in use.
  // This can be overwriten with passed-in query parameters.
  const minStockMaybe = isStockInUse(config) ? { minStock: 0 } : {};
  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};
  const featuredQuery = {}
  const perPage = params.perPage ?? RESULT_PAGE_SIZE;

  if (params.isFeatured) {
    Object.assign(featuredQuery, { pub_isFeatured: true });
  }

  const { aspectWidth = 1, aspectHeight = 1, variantPrefix = 'listing-card' } = config.listing;
  const aspectRatio = aspectHeight / aspectWidth;

  return searchListings({
    //...minStockMaybe,
    ...rest,
    ...originMaybe,
    ...featuredQuery,
    page,
    perPage,
    include: ['images', 'author.profileImage',],
    'fields.listing': ['title', 'geolocation', 'price', 'publicData'],
    'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
    'fields.image': [
      `variants.${variantPrefix}`,
      `variants.${variantPrefix}-2x`,
      // Avatars
      'variants.square-small',
      'variants.square-small2x',],
    ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
    ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
    'limit.images': 1,
  });
};
