import React, { Component } from 'react';
import { string, func, bool } from 'prop-types';


import css from './Concierge.module.css';




export const ConciergeComponent = props => {


  return (
    <a className={css.root} href="l/gritty-concierge-service/64261671-10b4-4bab-8488-90d64ec58401">

<img
  className={css.rootForImage}
  alt="Concierge"
  
  src="https://sharetribe.imgix.net/63720ee7-c7be-47e4-9009-9deb35d09d47/64262479-be02-419d-b41b-63524e3c5d0b?auto=format&crop=edges&fit=crop&h=400&w=400&s=1b33dc96d8d70233853314c0878e91f9"
/>
<div className={css.info}>
<div className={css.mainInfo}>
  <div className={css.title}>
    Gritty Concierge Service
  </div>
  <div className={css.subtitle}>
  Let us help you hire top talent.
  </div>

</div>
</div>
</a>

  );
};



export default ConciergeComponent;
