import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MailchimpSubscribe from "react-mailchimp-subscribe";
import css from './MailingList.module.css';
import { FormattedMessage } from 'react-intl';


const CustomForm = ({ status, message, onValidated }) => {

  const [email, setEmail] = useState('');


  useEffect(() => {
    if(status === "success") clearFields();
  }, [status])

  const clearFields = () => {
    setEmail('');
  }


  const handleSubmit = (e) => {
    e.preventDefault();
    email &&
    email.indexOf("@") > -1 &&
    onValidated({
        EMAIL: email,
    });
}

  return (
    <form className={css.form}
    onSubmit={(e) => handleSubmit(e)}
    >
      <h3 className={css.title}><FormattedMessage id="MailingList.title" /></h3>

      {status === "sending" && (
          <div className="mc__alert mc__alert--sending">
            sending...
          </div>
        )}
        {status === "error" && (
          <div 
            className={css.error}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
        {status === "success" && (
          <div
            className={css.success}
            dangerouslySetInnerHTML={{ __html: message }}
          />
        )}
      <div className={css.field}>

        <input
          label="Email"
          onChange={e => setEmail(e.target.value)}
          type="email"
          value={email}
          placeholder="your@email.com"
          isRequired
        />

      </div>

      <input
        label="subscribe"
        type="submit"
        formValues={[email]}
      />
    </form>
  );
};


const MailingList = props => {
  const { rootClassName, className } = props;
  const classes = classNames(rootClassName || css.root, className);

  const postUrl = `https://grittyinpink.us1.list-manage.com/subscribe/post?u=${process.env.REACT_APP_MAILCHIMP_U}&id=${process.env.REACT_APP_MAILCHIMP_ID}`;

  // prettier-ignore
  return (
    <div className={css.formContainer}>
        <MailchimpSubscribe
            url={postUrl}
            render={({ subscribe, status, message }) => (
                <CustomForm
                    status={status} 
                    message={message}
                    onValidated={formData => subscribe(formData)}
                />
            )}
        />
    </div>
);
};

MailingList.defaultProps = {
  rootClassName: null,
  className: null,
};

const { string } = PropTypes;

MailingList.propTypes = {
  rootClassName: string,
  className: string,
};

export default MailingList;
