import { types as sdkTypes } from '../util/sdkLoader';

const { LatLng, LatLngBounds } = sdkTypes;

// An array of locations to show in the LocationAutocompleteInput when
// the input is in focus but the user hasn't typed in any search yet.
//
// Each item in the array should be an object with a unique `id` (String) and a
// `predictionPlace` (util.types.place) properties.

const defaultLocations = [
  {
    id: 'default-la',
    predictionPlace: {
      address: 'Los Angeles, CA, USA',
      bounds: new LatLngBounds(new LatLng(34.161439, -118.126728), new LatLng(33.899991, -118.521447)),
    },
  },
  {
    id: 'default-nyc',
    predictionPlace: {
      address: 'New York City, NY, USA',
      bounds: new LatLngBounds(new LatLng(40.917577, -73.700272), new LatLng(40.477399, -74.259639922)),
    },
  },
  {
    id: 'default-denver',
    predictionPlace: {
      address: 'Denver, CO, USA',
      bounds: new LatLngBounds(new LatLng(39.914093266, -104.625596), new LatLng(39.617428, -105.116656)),
    },
  },
  {
    id: 'default-portland',
    predictionPlace: {
      address: 'Portland, OR, USA',
      bounds: new LatLngBounds(new LatLng(45.858097, -122.453655), new LatLng(45.424089, -122.919769)),
    },
  },

];
export default defaultLocations;
