import axios from 'axios';

const voucherifyApplicationId = process.env.REACT_APP_VOUCHERIFY_APPLICATION_ID;
const voucherifySecretKey = process.env.REACT_APP_VOUCHERIFY_SECRET_KEY;

export function validateVoucher(voucher) {
    const options = {
        method: 'POST',
        url: 'https://us1.api.voucherify.io/v1/vouchers/'+voucher+'/validate',
        headers: {
          accept: 'application/json',
          'X-App-Id': voucherifyApplicationId,
          'X-App-Token': voucherifySecretKey,
          'X-Voucherify-Channel': 'Voucherify Documentation',
          'content-type': 'application/json'
        },
        // data: {
        //   customer: {id: 'cust_ztSxSmEZr8a4rop60yzjVZIq'},
        //   session: {ttl: 7,  ttl_unit: 'DAYS'}
        // }
      };

      return new Promise((resolve, reject) => {
        axios
        .request(options)
            .then(res => {
                resolve(res)
            })
            .catch(err => reject(err))
    })

      

}



export function redeemVoucher(voucher) {
    const options = {
        method: 'POST',
        url: 'https://us1.api.voucherify.io/v1/vouchers/'+ voucher +'/redemption',
        headers: {
          accept: 'application/json',
          'X-App-Id': voucherifyApplicationId,
          'X-App-Token': voucherifySecretKey,
          'X-Voucherify-Channel': 'Voucherify Documentation',
          'content-type': 'application/json'
        },
        // data: {
        //   customer: {
        //     id: 'cust_ztSxSmEZr8a4rop60yzjVZIq',
        //     source_id: 'track_+EUcXP8WDKXGf3mYmWxbJvEosmKXi3Aw'
        //   },
        //   order: {amount: 1000},
        //   reward: {id: 'rew_eSi4eYJk7o3tOycvweicR2z7'},
        //   session: {type: 'LOCK'}
        // }
      };
      
      return new Promise((resolve, reject) => {
        axios
        .request(options)
            .then(res => {
                resolve(res)
            })
            .catch(err => reject(err))
    })
}


// todo: add more validation
export function handleVoucher(res) {
// Check type of voucher
const voucherType = res.data.voucher.discount.type;

if (voucherType === 'AMOUNT') {
// Type static value

  const discountAmount = res.data.voucher.discount.amount_off;
  const discountNegative = Math.abs(discountAmount) * -1
  const discount = {amount_off: discountNegative, currency: 'USD', type: 'AMOUNT'}
  return discount;
  

} else if (voucherType === 'PERCENT') {
// Type percentage
  const discountAmount = res.data.voucher.discount.percent_off;
  const discountNegative = Math.abs(discountAmount) * -1

  const discount = {percent_off: discountNegative, currency: 'USD', type: 'PERCENT'}
  return discount;

} else {
 // No match 
 console.log('Need more validation.');

}

}


// todo: add more validation
export function handleFakeVoucher(fakeVoucher) {
  // Check type of voucher
  const voucherType = fakeVoucher.type;
  
  if (voucherType === 'AMOUNT') {
  // Type static value
  
    const discountAmount = fakeVoucher.amount_off;
    const discountNegative = Math.abs(discountAmount) * -1
    const discount = {amount: discountNegative, currency: 'USD', type: 'AMOUNT'}
    return discount;
    
  
  } else if (voucherType === 'PERCENT') {
  // Type percentage
    const discountAmount = fakeVoucher.percent_off;
    const discountNegative = Math.abs(discountAmount) * -1
  
    const discount = {amount: discountNegative, currency: 'USD', type: 'PERCENT'}
    return discount;
  
  } else {
   // No match 
   console.log('Need more validation.');
  
  }
  
  }
  