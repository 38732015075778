import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes } from '../../util/types';

import css from './OrderBreakdown.module.css';
import { types } from '../../util/sdkLoader';
import config from '../../config';
const { Money } = types;

const LineItemUnitPriceMaybe = props => {
  const { transaction, unitType, intl, currentTransaction } = props;
  const isNightly = unitType === LINE_ITEM_NIGHT;
  const isDaily = unitType === LINE_ITEM_DAY;
  const translationKey = isNightly
    ? 'OrderBreakdown.pricePerNight'
    : isDaily
      ? 'OrderBreakdown.pricePerDay'
      : 'OrderBreakdown.pricePerQuantity';

  const { deposit = {} } = currentTransaction.attributes.protectedData || {};
  const unitPurchase = transaction.attributes.lineItems.find(
    item => item.code === unitType && !item.reversal
  );

  const unitPrice = deposit.amount ? new Money(unitPurchase.unitPrice.amount + Number(deposit.amount), config.currency) : unitPurchase.unitPrice;

  const formattedUnitPrice = unitPurchase ? formatMoney(intl, unitPrice) : null;

  return formattedUnitPrice ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} />
      </span>
      <span className={css.itemValue}>{formattedUnitPrice}</span>
    </div>
  ) : null;
};

LineItemUnitPriceMaybe.propTypes = {
  transaction: propTypes.transaction.isRequired,
  unitType: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemUnitPriceMaybe;
