import React, { Component } from 'react';
import { bool, func, object, string } from 'prop-types';
import { Form as FinalForm, Field } from 'react-final-form';
import classNames from 'classnames';

import config from '../../../config';
import { intlShape, injectIntl } from '../../../util/reactIntl';
import { isMainSearchTypeKeywords } from '../../../util/search';

import { Form, LocationAutocompleteInput } from '../../../components';

import css from './TopbarSearchForm.module.css';
import SearchFilter from './SearchFilter';


const identity = v => v;


class TopbarSearchFormComponent extends Component {
  constructor(props) {
    super(props);
    // onChange is used for location search
    this.onChange = this.onChange.bind(this);
    // onSubmit is used for keywords search
    this.onSubmit = this.onSubmit.bind(this);

    // Callback ref
    this.searchInput = null;
    this.setSearchInputRef = element => {
      this.setSearchInput = element;
    };
  }

  onChange(location) {


    const { appConfig, onSubmit } = this.props;
    if (!isMainSearchTypeKeywords(appConfig) && location.selectedPlace) {
      // Note that we use `onSubmit` instead of the conventional
      // `handleSubmit` prop for submitting. We want to autosubmit
      // when a place is selected, and don't require any extra
      // validations for the form.
      //onSubmit({ location });
      // blur search input to hide software keyboard
      this.searchInput?.blur();
    }
  }

  onSubmit(values) {
    console.log(values);
    console.log('values here woo')
    const { appConfig, onSubmit } = this.props;
    const { keywords, location } = values;
  
    // If the search is for keywords only, pass the `keywords` value to `onSubmit`
    if (isMainSearchTypeKeywords(appConfig)) {
      onSubmit({ keywords });
    } 
    // If the search is for location only, pass the `location` value to `onSubmit`
    else if (location && location.selectedPlace) {
      const { search, selectedPlace } = location;
      const { origin, bounds } = selectedPlace;
      onSubmit({ address: search, origin, bounds });
    } 
    // If the search is for both keywords and location, pass both `keywords` and `location` values to `onSubmit`
    else {
      onSubmit({ keywords, location });
    }
    // blur search input to hide software keyboard
    this.searchInput?.blur();
  }

  
  render() {
    const { onSubmit, appConfig, ...restOfProps } = this.props;
    const submit = onSubmit;
    return (
      <FinalForm
        {...restOfProps}
        onSubmit={submit}
        render={formRenderProps => {
          const {
            rootClassName,
            className,
            desktopInputRoot,
            intl,
            isMobile,
            handleSubmit,
          } = formRenderProps;
          const classes = classNames(rootClassName, className);
          const desktopInputRootClass = desktopInputRoot || css.desktopInputRoot;
    
          return (
            <Form className={classes} onSubmit={handleSubmit} enforcePagePreloadFor="SearchPage">
              <SearchFilter
                iconClass={classNames(isMobile ? css.mobileIcon : css.desktopIcon || css.icon)}
                rootClassName={css.searchFilterForm}
                labelClassName={css.searchLabel}
                inputRef={this.setSearchInputRef}
                intl={intl}
                isMobile={isMobile}
                onLocationChange={this.onChange}
                onSubmit={onSubmit}
              />
            </Form>
          );
        }}
      />
    );
  }
  
}

TopbarSearchFormComponent.defaultProps = {
  rootClassName: null,
  className: null,
  desktopInputRoot: null,
  isMobile: false,
  appConfig: config,
};

TopbarSearchFormComponent.propTypes = {
  rootClassName: string,
  className: string,
  desktopInputRoot: string,
  onSubmit: func.isRequired,
  isMobile: bool,
  appConfig: object,

  // from injectIntl
  intl: intlShape.isRequired,
};

const TopbarSearchForm = injectIntl(TopbarSearchFormComponent);

export default TopbarSearchForm;
