import React, { useRef, useState } from 'react';
import { bool, func, number, string } from 'prop-types';
import { Form as FinalForm, FormSpy } from 'react-final-form';

import config from '../../../config';
import { FormattedMessage, useIntl } from '../../../util/reactIntl';
import { propTypes } from '../../../util/types';
import { numberAtLeast, required } from '../../../util/validators';

import { formatMoney } from '../../../util/currency';
import { types as sdkTypes } from '../../../util/sdkLoader';


import {validateVoucher,redeemVoucher, handleVoucher, handleFakeVoucher} from '../../../util/voucherify';

import {
  Form,
  FieldSelect,
  FieldTextInput,
  InlineTextButton,
  PrimaryButton,
  SecondaryButton,
  FieldCurrencyInput
} from '../../../components';

import EstimatedCustomerBreakdownMaybe from '../EstimatedCustomerBreakdownMaybe';

import css from './ProductOrderForm.module.css';

const { Money } = sdkTypes;

const renderForm = formRenderProps => {
  const {
    // FormRenderProps from final-form
    handleSubmit,
    form: formApi,

    // Custom props passed to the form component
    intl,
    formId,
    currentStock,
    hasMultipleDeliveryMethods,
    listingId,
    isOwnListing,
    currentTransaction,
    onFetchTransactionLineItems,
    onContactUser,
    lineItems,
    fetchLineItemsInProgress,
    fetchLineItemsError,
    values,
  } = formRenderProps;

  const handleOnChange = formValues => {

    const { quantity: quantityRaw, deliveryMethod } = formValues.values;
    const quantity = Number.parseInt(quantityRaw, 10);
    const updatedPrice = { _sdkType: 'Money', ...currentTransaction.attributes.protectedData.newPrice };
    const deposit = { _sdkType: 'Money', ...currentTransaction.attributes.protectedData.deposit };
    const discountInput = document.getElementById('discountInput');


    const isBrowser = typeof window !== 'undefined';
    const orderData = lineItems && lineItems.discount ? { quantity, deliveryMethod, updatedPrice, discount } : { quantity, deliveryMethod, updatedPrice, deposit }

    if (isBrowser && quantity && deliveryMethod && !fetchLineItemsInProgress && !discountInput) {
      onFetchTransactionLineItems({
        orderData: orderData,
        listingId,
        isOwnListing,
      });
    }
  };

  // In case quantity and deliveryMethod are missing focus on that select-input.
  // Otherwise continue with the default handleSubmit function.
  const handleFormSubmit = e => {


    const discount = lineItems && lineItems[1] && lineItems[1].code === 
    "line-item/discount" ? lineItems[1].unitPrice : null;
    const { quantity, deliveryMethod } = values || {};
    values.discount = discount;


    


    if (!quantity || quantity < 1) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('quantity');
      formApi.focus('quantity');
    } else if (!deliveryMethod) {
      e.preventDefault();
      // Blur event will show validator message
      formApi.blur('deliveryMethod');
      formApi.focus('deliveryMethod');
    } else {
      handleSubmit(e);
    }
  };
    // Discount


    const discountLabel = intl.formatMessage({
      id: 'ProductOrderForm.discountLabel',
    });

    const discountPlaceholder = intl.formatMessage({
      id: 'ProductOrderForm.discountPlaceholder',
    });



    const updateDiscountCode = (e) => {
      e.preventDefault();
      e.stopPropagation();
      const updatedPrice = {_sdkType: 'Money',...currentTransaction.attributes.protectedData.newPrice};
      const deposit = {_sdkType: 'Money',...currentTransaction.attributes.protectedData.deposit};
      // const offerPrice = {_sdkType: 'Money',...currentTransaction.attributes.protectedData.offerPrice};
      const quantity = 1;
      const deliveryMethod = 'pickup';
      const discountCode = document.getElementById('discountInput').value;
      document.getElementById('discountInput').value = '';
      // COMMENT BELOW IF WE GO WITH VOUCHERIFY API
      // ALSO REMEMBER TO GRAB NAME FROM API AS WELL
      const fakeAmountVoucher = {amount_off: 2000, currency: 'USD', type: 'AMOUNT', name: '$20 Off', code: '20OFF'}
      const fakePercentVoucher = {percent_off: 10, currency: 'USD', type: 'PERCENT', name: 'No Commission', code: 'grittygang'}
      const tunecoreVoucher = {percent_off: 2, currency: 'USD', type: 'PERCENT', name: '2% Off Commission', code: 'tunecore'}
      const inpinkfam = {percent_off: 7, currency: 'USD', type: 'PERCENT', name: '7% Off Commission', code: 'inpinkfam'}



      if (discountCode.toLowerCase().trim() === 'grittygang') {
        const discount = handleFakeVoucher(fakePercentVoucher);

        onFetchTransactionLineItems({
          orderData: { quantity, deliveryMethod, updatedPrice, discount, deposit },
          listingId,
          isOwnListing,
        });


      }
      // else if (discountCode.toLowerCase() === '20off') {
      //   const discount = handleFakeVoucher(fakeAmountVoucher);

      //   onFetchTransactionLineItems({
      //     orderData: { quantity, deliveryMethod, updatedPrice, discount},
      //     listingId,
      //     isOwnListing,
      //   });

      // }
      else if (discountCode.toLowerCase().trim() === 'tunecore') {
        const discount = handleFakeVoucher(tunecoreVoucher);

        onFetchTransactionLineItems({
          orderData: { quantity, deliveryMethod, updatedPrice, discount},
          listingId,
          isOwnListing,
        });

      }

      else if (discountCode.toLowerCase().trim() === 'inpinkfam') {
        const discount = handleFakeVoucher(inpinkfam);

      onFetchTransactionLineItems({
        orderData: { quantity, deliveryMethod, updatedPrice, discount },
        listingId,
        isOwnListing,
      });

      }
      
      else {
        //TODO add handling here
      }
      //TODO UNCOMMENT when API sorted.
      // validateVoucher(discountCode)
      //   .then( res => {
      //     if (res.data['valid']) {
      //       redeemVoucher(discountCode).then( res => {
      //         const discount = handleVoucher(res);
      //         console.log(res.data.voucher);
      //          onFetchTransactionLineItems({
      //            orderData: { quantity, deliveryMethod, updatedPrice, discount},
      //            listingId,
      //            isOwnListing,
      //          });


      //       }) 
      //     }
      //     else {
      //       // TODO handle invalid UX here 
      //       console.log('INVALID');
      //     }
      //   })
      //   .catch(err => console.log(err.response))

    }

  const discountField = (
    <div className={css.discountField}>
    <Form onSubmit={updateDiscountCode}>
    <FieldTextInput
        id="discountInput"
        className={css.discount}
        name="discount"
        label={discountLabel}
        placeholder={discountPlaceholder}  
      />
      <SecondaryButton type="submit" className={css.discountButton}>
          <FormattedMessage id="ProductOrderForm.discountButton" />
      </SecondaryButton>
    </Form> 
  </div>
  )

  const breakdownData = {};

  const showBreakdown =
    breakdownData && lineItems && !fetchLineItemsInProgress && !fetchLineItemsError && !isOwnListing;

  const breakdown = showBreakdown ? (
    <div className={css.breakdownWrapper}>
      <h3>
        <FormattedMessage id="ProductOrderForm.breakdownTitle" />
      </h3>
      <EstimatedCustomerBreakdownMaybe
        unitType={config.lineItemUnitType}
        breakdownData={breakdownData}
        lineItems={lineItems}
        currentTransaction={currentTransaction}
      />
    </div>
  ) : null;

  const showContactUser = typeof onContactUser === 'function';

  const onClickContactUser = e => {
    e.preventDefault();
    onContactUser();
  };


  const contactSellerLink = (
    <InlineTextButton onClick={onClickContactUser}>
      <FormattedMessage id="ProductOrderForm.finePrintNoStockLinkText" />
    </InlineTextButton>
  );
  const quantityRequiredMsg = intl.formatMessage({ id: 'ProductOrderForm.quantityRequired' });

  const hasStock = currentStock && currentStock > 0;
  const quantities = hasStock ? [...Array(currentStock).keys()].map(i => i + 1) : [];
  const hasNoStockLeft = typeof currentStock != null && currentStock === 0;
  const hasOneItemLeft = typeof currentStock != null && currentStock === 1;


  const submitInProgress = fetchLineItemsInProgress;
  const submitDisabled = !hasStock;

  return (
    <div>
      <Form onSubmit={handleFormSubmit}>
      <FormSpy subscription={{ values: true }} onChange={handleOnChange} />
      {hasNoStockLeft ? null : hasOneItemLeft ? (
        <FieldTextInput
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          type="hidden"
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        />
      ) : (
        <FieldSelect
          id={`${formId}.quantity`}
          className={css.quantityField}
          name="quantity"
          disabled={!hasStock}
          label={intl.formatMessage({ id: 'ProductOrderForm.quantityLabel' })}
          validate={numberAtLeast(quantityRequiredMsg, 1)}
        >
          <option disabled value="">
            {intl.formatMessage({ id: 'ProductOrderForm.selectQuantityOption' })}
          </option>
          {quantities.map(quantity => (
            <option key={quantity} value={quantity}>
              {intl.formatMessage({ id: 'ProductOrderForm.quantityOption' }, { quantity })}
            </option>
          ))}
        </FieldSelect>
      )}
 
      {breakdown}
      {discountField}


      <div className={css.submitButton}>
        <PrimaryButton type="submit" inProgress={submitInProgress} disabled={submitDisabled}>
          {hasStock ? (
            <FormattedMessage id="ProductOrderForm.ctaButton" />
          ) : (
            <FormattedMessage id="ProductOrderForm.ctaButtonNoStock" />
          )}
        </PrimaryButton>
      </div>
      <p className={css.finePrint}>
        {hasStock ? (
          <FormattedMessage id="ProductOrderForm.finePrint" />
        ) : showContactUser ? (
          <FormattedMessage id="ProductOrderForm.finePrintNoStock" values={{ contactSellerLink }} />
        ) : null}
      </p>
    </Form>
      </div>

  );
};

const ProductOrderForm = props => {
  const intl = useIntl();
  const { price, currentTransaction, pickupEnabled, shippingEnabled } = props;

  // Should not happen for listings that go through EditListingWizard.
  // However, this might happen for imported listings.

  if (!price) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingPriceMissing" />
      </p>
    );
  }
  if (price.currency !== config.currency) {
    return (
      <p className={css.error}>
        <FormattedMessage id="ProductOrderForm.listingCurrencyInvalid" />
      </p>
    );
  }

  const singleDeliveryMethodAvailableMaybe =
    shippingEnabled && !pickupEnabled
      ? { deliveryMethod: 'shipping' }
      : !shippingEnabled && pickupEnabled
      ? { deliveryMethod: 'pickup' }
      : {};
  const hasMultipleDeliveryMethods = pickupEnabled && shippingEnabled;
  const initialValues = { quantity:1, ...singleDeliveryMethodAvailableMaybe };

  return (
    <FinalForm
      initialValues={initialValues}
      hasMultipleDeliveryMethods={hasMultipleDeliveryMethods}
      {...props}
      intl={intl}
      render={renderForm}
    />
  );
};

ProductOrderForm.defaultProps = {
  rootClassName: null,
  className: null,
  price: null,
  currentStock: null,
  listingId: null,
  isOwnListing: false,
  lineItems: null,
  fetchLineItemsError: null,
};

ProductOrderForm.propTypes = {
  rootClassName: string,
  className: string,

  // form
  formId: string.isRequired,
  onSubmit: func.isRequired,

  // listing
  listingId: propTypes.uuid,
  price: propTypes.money,
  currentStock: number,
  isOwnListing: bool,

  // line items
  lineItems: propTypes.lineItems,
  onFetchTransactionLineItems: func.isRequired,
  fetchLineItemsInProgress: bool.isRequired,
  fetchLineItemsError: propTypes.error,

  // other
  onContactUser: func,
};

export default ProductOrderForm;
