import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import config from '../../config';
import LogoImage from './INPINKbeta.jpg';
import css from './Logo.module.css';

const Logo = props => {
  const { className, format, ...rest } = props;
  const isMobile = format !== 'desktop';
  const classes = classNames(className, { [css.logoMobile]: isMobile });

  return (
    <img
      className={classes}
      src={LogoImage}
      alt={config.siteTitle}
      {...rest}
    />
  );
};

export default Logo;
