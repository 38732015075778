import React from 'react';

import css from './LocationAutocompleteInput.module.css';

const IconHourGlass = () => (
  <svg
  aria-describedby="desc"
  aria-labelledby="title"
  role="img"
  viewBox="0 0 64 64"
  xmlns="http://www.w3.org/2000/svg"
  xmlnsXlink="http://www.w3.org/1999/xlink"
>
  <title>
    Pin
  </title>
  <desc>
    A solid styled icon from Orion Icon Library.
  </desc>
  <path
    d="M32 2a20 20 0 0 0-20 20c0 18 20 40 20 40s20-22 20-40A20 20 0 0 0 32 2zm0 28a8 8 0 1 1 8-8 8 8 0 0 1-8 8z"
    data-name="layer1"
    fill="#202020"
  />
</svg>
);

export default IconHourGlass;
