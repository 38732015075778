import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { propTypes } from '../../util/types';
import { bool } from 'prop-types';

import css from './OrderBreakdown.module.css';

const LineItemDepositMaybe = props => {
  const { currentTransaction, intl } = props;
  let lineItem = null;


  const depositAmount = '$'+currentTransaction.attributes.protectedData.deposit.amount/100;
  

  // TODO: Remove hardcoding
  // please remove hardcoding
  if (currentTransaction.attributes.protectedData.deposit.amount > 0) {
    //const formattedDepositAmount = formatMoney(intl, depositAmount);
    return lineItem = (
        <>
          <div className={css.lineItem}>
          <div className={css.itemLabel}>Deposit *</div>

            <div className={css.itemValue}>{depositAmount}</div>
          </div>
        </>
      );
  }



  return (
    <div>
    {lineItem}

    </div>
  );
};

LineItemDepositMaybe.propTypes = {
    currentTransaction: propTypes.currentTransaction,
  intl: intlShape,

};

export default LineItemDepositMaybe;
