/*
 * Marketplace specific configuration.
 *
 * Every filter needs to have following keys:
 * - id:     Unique id of the filter.
 * - label:  The default label of the filter.
 * - type:   String that represents one of the existing filter components:
 *           BookingDateRangeFilter, KeywordFilter, PriceFilter,
 *           SelectSingleFilter, SelectMultipleFilter.
 * - group:  Is this 'primary' or 'secondary' filter?
 *           Primary filters are visible on desktop layout by default.
 *           Secondary filters are behind "More filters" button.
 *           Read more from src/containers/SearchPage/README.md
 * - queryParamNames: Describes parameters to be used with queries
 *                    (e.g. 'price' or 'pub_amenities'). Most of these are
 *                    the same between webapp URLs and API query params.
 *                    You can't change 'dates', 'price', or 'keywords'
 *                    since those filters are fixed to a specific attribute.
 * - config: Extra configuration that the filter component needs.
 *
 * Note 1: Labels could be tied to translation file
 *         by importing FormattedMessage:
 *         <FormattedMessage id="some.translation.key.here" />
 *
 * Note 2: If you need to add new custom filter components,
 *         you need to take those into use in:
 *         src/containers/SearchPage/FilterComponent.js
 *
 * Note 3: If you just want to create more enum filters
 *         (i.e. SelectSingleFilter, SelectMultipleFilter),
 *         you can just add more configurations with those filter types
 *         and tie them with correct extended data key
 *         (i.e. pub_<key> or meta_<key>).
 */

export const filters = [
  {
    id: 'parentCategory',
    label: 'Category',
    group: 'primary',
    queryParamNames: ['pub_parentCategory'],
    config: {
      schemaType: 'enum',
      options: [
        { 
       key: 'business', 
        label: 'Business',
       },
        {
           key: 'graphics-and-design', 
           label: 'Graphics & Design',
        },
        {
           key: 'local-services', 
           label: 'Local Services',
        },
        {
           key: 'marketing', 
           label: 'Marketing',
        },
        {
           key: 'music-and-audio', 
           label: 'Music & Audio',
        },
        {
           key: 'live-talent', 
           label: 'Live Talent',
        },
      ],
    },
  },
  {
    id: 'category',
    label: 'Category',
    type: 'CategoryFilter',
    group: 'primary',
    queryParamNames: ['pub_category'],
    config: {
      schemaType: 'enum',
      // "key" is the option you see in Flex Console.
      // "label" is set here for the UI only.
      // Note: label is not added through the translation files
      // to make filter customizations a bit easier.
      options: [
        { key: 'accounting', label: 'Accounting', category: 'business' },
        { key: 'booking', label: 'Booking', category: 'business' },
        { key: 'event-production', label: 'Event Production', category: 'business' },
        { key: 'career-coaching', label: 'Career Coaching', category: 'business' },
        { key: 'writing-editing', label: 'Writing & Editing', category: 'business' },
        { key: 'management', label: 'Management', category: 'business' },
  
        { key: 'graphic-design', label: 'Graphic Design', category: 'graphics-and-design' },
        { key: 'webDesignDevelopment', label: 'Web Design & Development', category: 'graphics-and-design' },
        { key: 'photo-video-editing', label: 'Photo & Video Editing', category: 'graphics-and-design' },
        { key: 'illustrators', label: 'Illustrators', category: 'graphics-and-design' },
  
        { key: 'hairMakeupStylists', label: 'Hair & Makeup', category: 'local-services' },
        { key: 'photo-video', label: 'Photo', category: 'local-services' },
        { key: 'video', label: 'Video', category: 'local-services' },
  
  
        { key: 'liveSoundtechs', label: 'Live Soundtechs', category: 'local-services' },
  
        { key: 'brand-consulting', label: 'Brand Consulting', category: 'marketing' },
        { key: 'influencers', label: 'Influencers', category: 'marketing' },
  
        // { key: 'playlistRadioPromotion', label: 'Playlist & Radio Promotion', category: 'marketing' },
        { key: 'socialMediaMarketing', label: 'Social Media Marketing', category: 'marketing' },
        { key: 'public-relations', label: 'Public Relations & Promotion', category: 'marketing' },
  
        { key: 'drums', label: 'Drums', category: 'music-and-audio' },
        { key: 'bass', label: 'Bass', category: 'music-and-audio' },
        { key: 'mixing-mastering', label: 'Mixing and Mastering', category: 'music-and-audio' },
        { key: 'guitar', label: 'Guitar', category: 'music-and-audio' },
        { key: 'lessons', label: 'Lessons', category: 'music-and-audio' },
        { key: 'keys', label: 'Keys', category: 'music-and-audio' },
        { key: 'recording-producing', label: 'Recording and Producing', category: 'music-and-audio' },
        { key: 'songwriting', label: 'Songwriting', category: 'music-and-audio' },
        { key: 'vocals', label: 'Vocals', category: 'music-and-audio' },
        { key: 'strings', label: 'Strings', category: 'music-and-audio' },
        { key: 'horns-brass', label: 'Woodwinds, Horns & Brass', category: 'music-and-audio' },
  
  
        { key: 'live-music', label: 'Live Music', category: 'live-talent' },
        { key: 'dJs', label: 'DJs', category: 'live-talent' },
        { key: 'hosts-other', label: 'Hosts / Other', category: 'live-talent' },
  
      ],
    },
  },
  
  {
    id: 'genre',
    label: 'Genre',
    type: 'SelectMultipleFilter',
    group: 'secondary',
    queryParamNames: ['pub_genre'],
    config: {
      schemaType: 'multi-enum',
      searchMode: 'has_all',
      options: [
        {
          key: 'alt-indie',
          label: 'Alternative / Indie',
        },

        {
          key: 'country',
          label: 'Country',
        },
        {
          key: 'electronic',
          label: 'Electronic',
        },
        {
          key: 'hiphop',
          label: 'Hiphop / Rap',
        },

        {
          key: 'pop',
          label: 'Pop',
        },
        {
          key: 'rnb-soul',
          label: 'R&B / Soul',
        },
        {
          key: 'rock',
          label: 'Rock',
        },
        {
          key: 'other',
          label: 'Other',
        },

      ],
    },
  },

{
  // If we want to add this back to our search parameters, we would just need to add a type for it.
  id: 'listingType',
  label: 'Listing Type',
  group: 'secondary',
  queryParamNames: ['pub_listingType'],
  config: {
    schemaType: 'enum',
    options: [
      {key:'hourly', label:'Hourly'},
      {key:'daily', label:'Daily'},
      {key:'flat', label:'Flat'},

    ],
  },
},
{
  id: 'diversityTags',
  label: 'Diversity Tags',
  type: 'SelectMultipleFilter',
  group: 'secondary',
  queryParamNames: ['pub_diversityTags'],
  config: {
    schemaType: 'multi-enum',
    searchMode: 'has_all',
    options: [
      {
        key: 'asian',
        label: 'Asian',
      },
      {
        key: 'black',
        label: 'Black',
      },
      {
        key: 'indigenous',
        label: 'Indigenous',
      },
      {
        key: 'latinx',
        label: 'Hispanic / Latina',
      },
      {
        key: 'queer',
        label: 'Queer',
      },
      {
        key: 'poc',
        label: 'POC',
      },
    ],
  },
},

{
  id: 'serviceOffered',
  label: 'Onsite or Remote',
  type: 'SelectSingleFilter',
  group: 'secondary',
  queryParamNames: ['pub_serviceOffered'],
  config: {
    schemaType: 'multi-enum',
    options: [
      {key:'local', label:'Onsite'},
      {key:'remote', label:'Remote'},
    ],
  },
},

{
  id: 'price',
  label: 'Price',
  type: 'PriceFilter',
  group: 'primary',
  // Note: PriceFilter is fixed filter,
  // you can't change "queryParamNames: ['price'],"
  queryParamNames: ['price'],
  // Price filter configuration
  // Note: unlike most prices this is not handled in subunits
  config: {
options: []},
},
{
  id: 'highestPrice',
  label: 'Highest Price',
  type: 'HighestPriceFilter',
  group: 'primary',
  // Note: PriceFilter is fixed filter,
  // you can't change "queryParamNames: ['price'],"
  queryParamNames: ['pub_highestPrice'],
  // Price filter configuration
  // Note: unlike most prices this is not handled in subunits
  config: {
    min: 0,
    max: 10000,
    step: 5,
  },
},
{
  id: 'keyword',
  label: 'Keyword',
  type: 'KeywordFilter',
  group: 'primary',
  // Note: KeywordFilter is fixed filter,
  // you can't change "queryParamNames: ['keywords'],"
  queryParamNames: ['keywords'],
  // NOTE: If you are ordering search results by distance
  // the keyword search can't be used at the same time.
  // You can turn on/off ordering by distance from config.js file.
  config: {},
},

// Here is an example of multi-enum search filter.
//
// {
//   id: 'amenities',
//   label: 'Amenities',
//   type: 'SelectMultipleFilter',
//   group: 'secondary',
//   queryParamNames: ['pub_amenities'],
//   config: {
//     // Schema type options: 'enum', 'multi-enum'
//     // Both types can work so that user selects multiple values when filtering search results.
//     // With "enum" the functionality will be OR-semantics (Nike OR Adidas OR Salomon)
//     // With "multi-enum" it's possible to use both AND and OR semantics with searchMode config.
//     schemaType: 'multi-enum',

//     // Optional modes: 'has_all', 'has_any'
//     // Note: this is relevant only for schema type 'multi-enum'
//     // https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
//     searchMode: 'has_all',

//     // "key" is the option you see in Flex Console.
//     // "label" is set here for this web app's UI only.
//     // Note: label is not added through the translation files
//     // to make filter customizations a bit easier.
//     options: [
//       { key: 'towels', label: 'Towels' },
//       { key: 'bathroom', label: 'Bathroom' },
//       { key: 'swimming_pool', label: 'Swimming pool' },
//       { key: 'barbeque', label: 'Barbeque' },
//     ],
//   },
// },
];

export const sortConfig = {
// Enable/disable the sorting control in the SearchPage
active: true,

// Note: queryParamName 'sort' is fixed,
// you can't change it since Flex API expects it to be named as 'sort'
queryParamName: 'sort',

// Internal key for the relevance option, see notes below.
relevanceKey: 'relevance',

// Relevance key is used with keywords filter.
// Keywords filter also sorts results according to relevance.
relevanceFilter: 'keywords',

// Keyword filter is sorting the results by relevance.
// If keyword filter is active, one might want to disable other sorting options
// by adding 'keyword' to this list.
conflictingFilters: [],

options: [
  { key: 'meta_isPromoted', label: 'Featured' },
  { key: 'createdAt', label: 'Newest' },
  { key: '-createdAt', label: 'Oldest' },
  { key: '-price', label: 'Lowest price' },
  { key: 'price', label: 'Highest price' },

  // The relevance is only used for keyword search, but the
  // parameter isn't sent to the Marketplace API. The key is purely
  // for handling the internal state of the sorting dropdown.
  { key: 'relevance', label: 'Relevance', longLabel: 'Relevance (Keyword search)' },
],
};

export const listing = {
// These should be listing details from public data with schema type: enum
// SectionDetailsMaybe component shows these on listing page.
enumFieldDetails: ['size', 'brand', 'category'],
};
