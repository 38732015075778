import React from 'react';
import { func, object, shape, string } from 'prop-types';
import { Field } from 'react-final-form';
import loadable from '@loadable/component';
import { ValidationError } from '../../../components';
import { Form, LocationAutocompleteInput } from '../../../components';
import css from './TopbarSearchForm.module.css';
import IconSearchDesktop from './IconSearchDesktop';


// LocationAutocompleteInputImpl is a big component that includes code for both Mapbox and Google Maps
// It is loaded dynamically - i.e. it is splitted to its own code chunk.
const LocationAutocompleteInputImpl = loadable(() =>
  import(/* webpackChunkName: "LocationAutocompleteInputImpl" */ '../../../components/LocationAutocompleteInput/LocationAutocompleteInputImpl')
);

const SearchFilter = props => {
  const {
    rootClassName,
    labelClassName,
    inputRef,
    intl,
    isMobile,
    onLocationChange,
    onSubmit,
    iconClass
  } = props;


  const handleSubmit = values => {
    const { keywords, location } = values;
    onSubmit(keywords, location);
  };

  return (
    <form className={rootClassName} onSubmit={handleSubmit}>

      <Field
        name="keywords"
        render={({ input }) => (
          <input
            {...input}
            className={isMobile ? css.mobileInput : css.desktopInput}
            type="text"
            placeholder={intl.formatMessage({
              id: 'TopbarSearchForm.keywordsPlaceholder',
            })}
            autoComplete="off"
          />
        )}
      />
      <Field
  name="location"
  render={({ input }) => {
    const { onChange: locationOnChange, ...restLocationInput } = input;

    // Merge the standard onChange function with custom behavior.
    const searchOnChange = value => {
      locationOnChange(value);
      onLocationChange(value);
    };

    return (
      <LocationAutocompleteInputImpl
        inputRef={inputRef}
        input={{ ...restLocationInput, onChange: searchOnChange }}
        hideErrorMessage
        placeholder={intl.formatMessage({
          id: 'TopbarSearchForm.locationPlaceholder',
        })}
      />
    );
  }}
/>
<button className={css.searchSubmit}>
        <div className={iconClass}>
          <IconSearchDesktop />
        </div>
      </button>
    </form>
  );
};

SearchFilter.propTypes = {
  rootClassName: string.isRequired,
  labelClassName: string.isRequired,
  inputRef: object.isRequired,
  intl: object.isRequired,
  isMobile: string.isRequired,
  onLocationChange: func.isRequired,
  onSubmit: func.isRequired,
};

export default SearchFilter;
