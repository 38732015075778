import React, { useState, useEffect } from 'react';
import { injectIntl } from 'react-intl';
import { Form as FinalForm, Field, FormSpy } from 'react-final-form';
import LocationAutocompleteInputImpl from './LocationAutocompleteInputImpl';

function useDebounce(value, delay) {
    const [debouncedValue, setDebouncedValue] = useState(value);
    useEffect(() => {
        const handler = setTimeout(() => {
            setDebouncedValue(value);
        }, delay);
        return () => {
            clearTimeout(handler);
        };
    }, [value, delay]);
    return debouncedValue;
}

const LocationAutoCompleteSearchField = ({ handleLocationChange, intl }) => {
    const [searchLocation, setSearchLocation] = useState({});
    const debouncedSearchLocation = useDebounce(searchLocation, 1000);

    useEffect(() => {
        handleLocationChange(debouncedSearchLocation.selectedPlace);
    }, [debouncedSearchLocation]);

    return (
        <FinalForm
            onSubmit={handleLocationChange}
            render={({ handleSubmit }) => {
                return (
                    <form onSubmit={handleSubmit}>
                        <FormSpy onChange={e => setSearchLocation(e.values.location ?? {})} />
                        <Field
                            name="location"
                            format={v => v}
                            component={LocationAutocompleteInputImpl}
                            placeholder={intl.formatMessage({ id: 'LocationSearchForm.placeholder' })}
                        />
                    </form>
                );
            }}
        />
    );
};

export default injectIntl(LocationAutoCompleteSearchField);